@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-gray-100 bg-[#282828] font-nunito;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h1 {
  @apply font-nunito text-[76.29px];
}

.h2 {
  @apply font-nunito text-[61px];
}

.h3 {
  @apply font-nunito text-[48.8px];
}

.h4 {
  @apply font-nunito text-[39.1px];
}

.h5 {
  @apply font-nunito text-[31.3px];
}

.h6 {
  @apply font-nunito text-[25px];
}

.p-lg {
  @apply font-montserrat text-[20px];
}

.p {
  @apply font-montserrat text-[16px];
}

.p-sm {
  @apply font-montserrat text-[12.8px];
}